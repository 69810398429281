export function isEmail(email: string): boolean {
    const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,6}$/;

    return regex.test(email);
}

export function checkInputValueLength(value: string, minLength: number = 3): boolean {
    return value.trim().length >= minLength;
}

export default { isEmail, checkInputValueLength };
