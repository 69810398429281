import { isActiveCookie, setCookie } from './utils/cookie';
import { pushToDl, GTMDataLayerItem } from './parts/tracking';
import { insertTemplate } from './utils/dom';
import { isEmail } from './utils/validate';

class NewsLetterSubscribePopup {
    subscribeModal: HTMLElement | null = null;

    subscribeTemplate: HTMLElement | null = null;

    messagesContainer: HTMLElement | null = null;

    messageRequired: HTMLElement | null = null;

    messageError: HTMLElement | null = null;

    messageSuccess: HTMLElement | null = null;

    emailInput: HTMLInputElement | null = null;

    submitButton: HTMLButtonElement | null = null;

    closeButton: HTMLElement | null = null;

    msgElementClasses = {
        general: 'subscribe__message',
        visible: 'subscribe__message_visible',
        hidden: 'subscribe__message_hidden',
        error: 'subscribe__message_error',
    };

    modalElementClasses = {
        hidden: 'subscribe_hidden',
        visible: 'subscribe_visible',
    };

    dlEvents: { [key: string]: GTMDataLayerItem } = {
        popupLoad: {
            event: 'popupEvent',
            eventCategory: 'popupNewsletter',
            eventAction: 'load',
            eventLabel: 'Newsletter subscribe',
        },
        popupClose: {
            event: 'popupEvent',
            eventCategory: 'popupNewsletter',
            eventAction: 'close',
            eventLabel: 'Newsletter subscribe',
        },
        subscribed: {
            event: 'popupEvent',
            eventCategory: 'popupNewsletter',
            eventAction: 'subscribed',
            eventLabel: 'Subscribed newsletter',
        },
    };

    cookies = {
        closed: 'sbscr_close',
        subscribed: 'sbscr_ok',
    };

    constructor() {
        this.subscribeModal = document.querySelector<HTMLElement>('[data-js-subscribe]');
        this.subscribeTemplate = document.querySelector<HTMLElement>('#subscribe-template');

        if (
            this.subscribeModal
            && this.subscribeTemplate
            && !(isActiveCookie(this.cookies.subscribed) || isActiveCookie(this.cookies.closed))
        ) {
            this.insertPopup();
        }
    }

    insertPopup(): void {
        if (this.subscribeModal) {
            insertTemplate(this.subscribeTemplate, this.subscribeModal);
        }

        this.subscribeModal?.classList.add(this.modalElementClasses.visible);

        this.emailInput = document.querySelector<HTMLInputElement>('[data-js-subscribe-input]');
        this.submitButton = document.querySelector<HTMLButtonElement>('[data-js-subscribe-submit]');
        this.closeButton = document.querySelector<HTMLElement>('[data-js-subscribe-close]');
        this.messagesContainer = document.querySelector<HTMLElement>('[data-js-subscribe-messages]');
        this.messageRequired = document.querySelector<HTMLElement>('[data-js-subscribe-required]');
        this.messageError = document.querySelector<HTMLElement>('[data-js-subscribe-error]');
        this.messageSuccess = document.querySelector<HTMLElement>('[data-js-subscribe-success]');

        pushToDl(this.dlEvents.popupLoad);

        this.addEventHandlers();
    }

    hideMessage(messageClass: HTMLElement | null): void {
        setTimeout(() => {
            messageClass?.classList.add(this.msgElementClasses.hidden);
        }, 3000);
    }

    hideModal(): void {
        this.subscribeModal?.classList.add(this.modalElementClasses.hidden);
    }

    addEventHandlers(): void {
        this.closeButton?.addEventListener('click', () => {
            pushToDl(this.dlEvents.popupClose);

            setCookie(this.cookies.closed, 7);
            this.hideModal();
        });

        this.submitButton?.addEventListener('click', (e: Event) => {
            this.handleSubmit(e);
        });
    }

    async handleSubmit(submitEvent: Event): Promise<void> {
        submitEvent.preventDefault();

        const email = this.emailInput?.value;

        if (!email) {
            this.messageRequired?.classList.add(this.msgElementClasses.visible);
            this.hideMessage(this.messageRequired);

            return;
        }

        if (!isEmail(email)) {
            this.messageError?.classList.add(this.msgElementClasses.visible);
            this.hideMessage(this.messageError);

            return;
        }

        pushToDl(this.dlEvents.subscribed);

        const response = await fetch((submitEvent.target as HTMLElement)?.closest('form')?.getAttribute('action') || '', {
            method: 'POST',
            body: JSON.stringify({
                action: 'subscribe_user',
                email,
            }),
        });

        this.handleSubscribeResponse.call(this, response);
    }

    handleSubscribeResponse(response: Response): void {
        if (response.status === 200) {
            this.messageSuccess?.classList.add(this.msgElementClasses.visible);
            this.hideMessage(this.messageSuccess);

            if (this.emailInput) {
                this.emailInput.value = '';
            }

            setCookie(this.cookies.subscribed, 365);

            setTimeout(() => {
                this.hideModal();
            }, 4000);
        } else {
            const message = document.createElement('span');
            const children = [].slice.call(this.messagesContainer?.children);

            response.text().then((text: string) => {
                message.innerHTML = JSON.parse(text);
                message.classList.add(this.msgElementClasses.general, this.msgElementClasses.error, this.msgElementClasses.visible, 'space', 'space_size_n');
            });

            children.forEach((element: HTMLElement) => {
                element.classList.add(this.msgElementClasses.hidden);
            });

            this.messagesContainer?.append(message);

            setTimeout(() => {
                message.parentNode?.removeChild(message);
            }, 5000);

            if (this.emailInput) {
                this.emailInput.value = '';
            }
        }
    }
}

// eslint-disable-next-line no-new
new NewsLetterSubscribePopup();

export {};
